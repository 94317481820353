export default `
    @page {
        margin: 0mm;
        size: potrait;
    }
    .main * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        
    }
    .main {
        text-align: center;
    }
    .text-left {
        text-align: left !important;
    }
    .main {
        margin: 0 auto;
        min-width: calc(210mm + 40px);
        min-height: calc(297mm + 30px);
        padding: 30px 40px;
        display: block;
    }
    .main p {
        margin-bottom: 0;
    }
    .line {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        border-bottom: solid blue 10px;
      }
    .thin-line {
        display: block;
        width: 100%;
        border-bottom: solid blue 5px;
    }
`;
