<template>
  <v-container fluid>
    <template>
      <v-container class="mt-4">
        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center text-md-left">
            <atoms-title class="py-4">Printout Shift</atoms-title></v-col
          >
          <v-col cols="12" md="6" align="end" class="d-flex align-center justify-center justify-md-end">
            <atoms-button :disabled="!payload" :loading="mainLoading" class="primary" @click.prevent="print">
              <v-icon left> mdi-printer </v-icon>
              Print Preview
            </atoms-button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-container>
      <!--   -->
      <v-skeleton-loader v-if="mainLoading" type="image"></v-skeleton-loader>
      <v-card
        v-else-if="!mainLoading && payload"
        light
        class="pa-2 black--text main"
        id="printShift"
        elevation="0"
        outlined
        :loading="mainLoading"
      >
        <div>
          <div class="line"></div>
          <div class="thin-line"></div>
          <br />
          <br />
          <div>
            <div class="text-center">
              <img width="25%" src="/hayokerja.png" alt="logo" />
              <atoms-title h3>Scan QR Code berikut untuk melakukan absensi</atoms-title>
            </div>
            <br />
            <div :class="[(!$vuetify.breakpoint.smAndDown && 'px-10') || '']">
              <img
                v-if="payload.qrCode"
                :width="$vuetify.breakpoint.smAndDown ? '50%' : '25%'"
                :src="payload.qrCode"
                alt="logo"
              />
              <br />
              <br />
              <atoms-title>{{ (payload.client && payload.client.name) || '-' }}</atoms-title>
              <br />
              <div class="text-left px-2" :style="`display: inline-block; margin: auto; width: 25%;`">
                <atoms-text><span class="font-weight-bold">Area : </span> {{ payload.areaTitle || '-' }}</atoms-text>
                <atoms-text><span class="font-weight-bold">Lokasi : </span> {{ payload.location || '-' }}</atoms-text>
                <atoms-text><span class="font-weight-bold">Shift : </span> {{ payload.title || '-' }}</atoms-text>
              </div>
            </div>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#000fff" fill-opacity="1" d="M0,224L1440,128L1440,320L0,320Z"></path>
        </svg>
      </v-card>
      <v-container v-else class="d-flex justify-center align-center my-8 py-8 outlined">
        <div class="text-center">
          <atoms-image :width="250" src="/searching.svg" />
          <atoms-title :h3="true" class="mt-6">Gagal membaca shift yang dimaksud!</atoms-title>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
import printJS from 'print-js'
export default {
  metaInfo: {
    title: 'Cetak',
  },
  data() {
    return {
      showValidator: false,
      mainLoading: false,
      payload: null,
      rawPayload: null,
    }
  },
  async mounted() {
    const { _id } = this.$route.params

    if (_id) {
      await this.getShift(_id)
    }
  },
  methods: {
    async getShift(_id) {
      this.mainLoading = true
      try {
        const _resp = await this.$api.get('QuotationItems/getShifts', {
          params: {
            jsonQuery: JSON.stringify({
              _id,
              pipeline: [
                {
                  $lookup: {
                    as: 'client',
                    from: 'Clients',
                    localField: 'clientId',
                    foreignField: '_id',
                    pipeline: [
                      {
                        $project: {
                          logo: 1,
                          name: 1,
                          address: 1,
                        },
                      },
                    ],
                  },
                },
                {
                  $unwind: {
                    path: '$client',
                    preserveNullAndEmptyArrays: true,
                  },
                },
              ],
            }),
            limit: 1,
          },
        })
        if (_resp?.success) {
          this.payload = _resp?.result?.[0]
          this.rawPayload = _resp
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.mainLoading = false
      }
    },
    print() {
      const style = require('./styles.js').default
      printJS({
        printable: 'printShift',
        type: 'html',
        style: style,
        scanStyles: true,
      })
    },
  },
}
</script>
<style scoped lang="scss">
@page {
  margin: 0mm;
  size: potrait;
}
.main * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main {
  text-align: center;
}
.line {
  margin-bottom: 5px;
  width: 100%;
  border-bottom: solid blue 10px;
}
.thin-line {
  width: 100%;
  border-bottom: solid blue 5px;
}
.text-left {
  text-align: left !important;
}
.main {
  margin: 0 auto;
  min-width: calc(210mm + 40px);
  min-height: calc(297mm + 30px);
  padding: 30px 40px;
  display: block;
}
.main p {
  margin-bottom: 0;
}
.main th {
  font-size: 80%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: solid grey 2px;
  margin-bottom: 20px;
}
.header img {
  width: 250px;
}
.header h1 {
  font-size: 40px;
  letter-spacing: 0.3px;
  margin-left: auto;
}
.user_from {
  margin-bottom: 20px;
}
.user_to {
  margin-bottom: 20px;
}
.user_head {
  font-weight: bold;
  color: grey;
}
.user_body {
  font-weight: bold;
  color: black;
}
.user_foot {
  word-wrap: break-word;
  width: 400px;
}
.user_pic {
  font-weight: bold;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.detail {
  margin-left: auto;
}
.detail .tabelDetail_title {
  font-weight: bold;
  padding: 0 10px;
  text-align: right;
}
.detail .tabelDetail_body {
  padding: 0 10px;
}
.tabelHarga {
  width: 100%;
}
.tabelHarga td {
  padding: 5px 5px;
}
.tabelHarga thead {
  text-align: center;
  background-color: #1e82e6;
  -webkit-print-color-adjust: exact;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}
.tabelHarga tbody td {
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: top;
}
.tabelHarga tbody tr:first-of-type td {
  padding-top: 5px;
}
.tabelHarga tbody tr:last-of-type td {
  padding-bottom: 5px;
}
.tabelHarga tbody .deskripsi_head {
  font-weight: bold;
}
.tabelHarga tbody .deskripsi_body {
  display: block;
  font-style: italic;
  padding-left: 15px;
}
.tabelHarga tbody .lokasi {
  text-align: center;
  align-items: center;
  max-width: 200px;
}
.tabelHarga tbody .jumlahPHL {
  text-align: center;
}
.tabelHarga tbody .jumlahHariKerja {
  text-align: center;
}
.tabelHarga tbody .harga_satuan {
  text-align: right;
}
.tabelHarga tbody .SLA {
  text-align: center;
}
.tabelHarga tbody .total {
  text-align: right;
}
.tabelHarga tfoot {
  font-weight: bold;
}
.tabelHarga tfoot tr:first-of-type td {
  border-top: 1px solid grey;
}
.tabelHarga tfoot .subTotal {
  padding-bottom: 0px;
  font-weight: bold;
}
.tabelHarga tfoot .subTotalContent {
  font-weight: bold;
  text-align: right;
}
.tabelHarga tfoot .subTotalContent.divider {
  padding-bottom: 0;
  border-bottom: 1.5px solid black;
}
.tabelHarga tfoot .pph {
  padding-top: 0px;
  padding-bottom: 0px;
  font-style: italic;
  font-weight: normal;
}
.tabelHarga tfoot .pphContent {
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: normal;
}
.tabelHarga tfoot .penguranganTagihan {
  padding-top: 0px;
  padding-bottom: 0px;
  font-style: italic;
  font-weight: normal;
}
.tabelHarga tfoot .penguranganTagihanContent {
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: normal;
}
.tabelHarga tfoot .total {
  padding-top: 15px;
}
.tabelHarga tfoot .totalContent {
  padding-top: 15px;
  font-weight: bold;
  text-align: right;
  border-bottom: 2px solid black;
}
.tabelHarga_footerDivider {
  margin-top: 5px;
  background-color: #1e82e6;
  -webkit-print-color-adjust: exact;
  height: 20px;
}
.catatan {
  margin-top: 20px;
  font-style: italic;
  margin-bottom: 20px;
}
.catatan_head {
  font-weight: bold;
}
.catatan_foot_head {
  padding-right: 200px;
}
.deskripsi {
  margin-bottom: 20px;
}
.deskripsi_head {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  font-style: italic;
}
.deskripsi_head_important {
  font-weight: bold;
}
.deskripsi_body {
  list-style: none;
}
.deskripsi_body li::before {
  content: '- ';
}
.sign {
  width: 218px;
  min-height: 62px;
  text-align: center;
  margin-left: auto;
}
.sign_head {
  padding-bottom: 125px;
}
.sign_body {
  font-weight: bold;
}
</style>
