export default `
@page {
    margin: 0mm;
    size: potrait;
}
.main * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "calibri";
}
.main {
    margin: 0 auto;
    min-width: calc(210mm + 40px);
    min-height: calc(297mm + 30px);
    padding: 30px 40px;
    display: block;
}
.main p {
    margin-bottom: 0;
}
.main th {
    font-size: 80%;
}
.main td {
    font-size: 80%;
}
.header {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: solid grey 2px;
    margin-bottom: 20px;
}
.header img {
    width: 250px;
}
.header h1 {
    font-size: 40px;
    letter-spacing: 0.3px;
    margin-left: auto;
}
.user_from {
    margin-bottom: 20px;
}
.user_to {
    margin-bottom: 20px;
}
.user_head {
    font-weight: bold;
    color: grey;
}
.user_body {
    font-weight: bold;
    color: black;
}
.user_foot {
    word-wrap: break-word;
    width: 400px;
}
.user_pic {
    font-weight: bold;
}
.wrapper {
    display: flex;
    justify-content: space-between;
}
.detail {
    margin-left: auto;
}
.detail .tabelDetail_title {
    font-weight: bold;
    padding: 0 10px;
    text-align: right;
}
.detail .tabelDetail_body {
    padding: 0 10px;
}
.tabelHarga {
    width: 100%;
}
.tabelHarga td {
    padding: 5px 5px;
}
.tabelHarga thead {
    text-align: center;
    background-color: #1e82e6;
    -webkit-print-color-adjust: exact;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}
.tabelHarga tbody td {
    padding-top: 3px;
    padding-bottom: 3px;
    vertical-align: top;
}
.tabelHarga tbody tr:first-of-type td {
    padding-top: 5px;
}
.tabelHarga tbody tr:last-of-type td {
    padding-bottom: 5px;
}
.tabelHarga tbody .deskripsi_head {
    font-weight: bold;
}
.tabelHarga tbody .deskripsi_body {
    display: block;
    font-style: italic;
    padding-left: 15px;
}
.tabelHarga tbody .lokasi {
    text-align: center;
    align-items: center;
    max-width: 200px;
}
.tabelHarga tbody .jumlahPHL {
    text-align: center;
}
.tabelHarga tbody .jumlahHariKerja {
    text-align: center;
}
.tabelHarga tbody .harga_satuan {
    text-align: right;
}
.tabelHarga tbody .SLA {
    text-align: center;
}
.tabelHarga tbody .total {
    text-align: right;
}
.tabelHarga tfoot {
    font-weight: bold;
}
.tabelHarga tfoot tr:first-of-type td {
    border-top: 1px solid grey;
}
.tabelHarga tfoot .subTotal {
    padding-bottom: 0px;
    font-weight: bold;
}
.tabelHarga tfoot .subTotalContent {
    font-weight: bold;
    text-align: right;
}
.tabelHarga tfoot .subTotalContent.divider {
    padding-bottom: 0;
    border-bottom: 1.5px solid black;
}
.tabelHarga tfoot .pph {
    padding-top: 0px;
    padding-bottom: 0px;
    font-style: italic;
    font-weight: normal;
}
.tabelHarga tfoot .pphContent {
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: normal;
}
.tabelHarga tfoot .penguranganTagihan {
    padding-top: 0px;
    padding-bottom: 0px;
    font-style: italic;
    font-weight: normal;
}
.tabelHarga tfoot .penguranganTagihanContent {
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: normal;
}
.tabelHarga tfoot .total {
    padding-top: 15px;
}
.tabelHarga tfoot .totalContent {
    padding-top: 15px;
    font-weight: bold;
    text-align: right;
    border-bottom: 2px solid black;
}
.tabelHarga_footerDivider {
    margin-top: 5px;
    background-color: #1e82e6;
    -webkit-print-color-adjust: exact;
    height: 20px;
}
.catatan {
    margin-top: 20px;
    font-style: italic;
    margin-bottom: 20px;
}
.catatan_head {
    font-weight: bold;
}
.catatan_foot_head {
    padding-right: 200px;
}
.deskripsi {
    margin-bottom: 20px;
}
.deskripsi_head {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    font-style: italic;
}
.deskripsi_head_important {
    font-weight: bold;
}
.deskripsi_body {
    list-style: none;
}
.deskripsi_body li {
    display: flex;
    align-items: flex-start;
}
.sign {
    width: 218px;
    min-height: 62px;
    text-align: center;
    margin-left: auto;
}
.sign_head {
    padding-bottom: 125px;
}
.sign_body {
    font-weight: bold;
}
`
