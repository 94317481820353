<template>
  <v-container fluid>
    <molecules-custom-dialog
      :show="showValidator"
      title="Kesalahan"
      caption="Terdapat kesalahan ketika mengenali data!"
      :items="[
        {
          title: 'Saya Paham',
          override: 'red--text',
          full: false,
          exec: () => $router.push('/quotation'),
        },
      ]"
    />
    <template>
      <v-container class="mt-4">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <atoms-title class="py-4 mr-2">Printout {{ headTitle }}</atoms-title>
          </v-col>
          <v-col cols="12" md="6" align="end" class="d-flex align-center justify-center justify-md-end">
            <atoms-button
              :loading="loadingFetchInvoice"
              class="primary px-md-8"
              :style="{
                width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
              }"
              @click.prevent="print"
            >
              <v-icon left> mdi-printer </v-icon>
              Print Preview
            </atoms-button>
          </v-col>

          <v-col cols="12" v-if="!$vuetify.breakpoint.smAndDown"><v-divider /></v-col>
          <v-col cols="12">
            <v-row dense align="end">
              <v-col cols="12" md="8">
                <atoms-text-field
                  label="Tambah Keterangan"
                  :noLabel="true"
                  v-model="item"
                  color="background"
                  :rules="($vuetify.breakpoint.smAndDown && []) || undefined"
                />
              </v-col>
              <v-col cols="12" md="2">
                <atoms-button
                  @click="
                    () => {
                      if (!editableId && item) {
                        descriptions.push({
                          _id: $uuid.v4(),
                          text: item,
                        })
                        item = ''
                      } else if (editableId && item) {
                        descriptions = [
                          ...descriptions.map((x) => {
                            if (x._id === editableId) {
                              return {
                                _id: editableId,
                                text: item,
                              }
                            }
                            return x
                          }),
                        ]
                        item = ''
                        editableId = null
                      } else {
                        !item &&
                          $showDialog({
                            title: 'Kesalahan',
                            body: 'Pastikan kolom terisi ketika menambah dan memperbarui!',
                          })
                      }
                    }
                  "
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  :class="!editableId ? 'primary' : 'orange'"
                  >{{ editableId ? 'Perbarui' : 'Tambah' }}</atoms-button
                >
              </v-col>
              <v-col cols="12" md="2">
                <atoms-button
                  @click="defaultDescriptions = null"
                  :loading="$store.state.loading"
                  :style="{ width: '100%' }"
                  class="red white--text"
                  >Settings Default</atoms-button
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <atoms-title :h3="true" class="primary--text">Edit Keterangan</atoms-title>
          </v-col>
          <v-col cols="12" v-if="descriptions && descriptions.length > 0">
            <v-row
              v-for="desc in descriptions"
              :key="desc._id"
              dense
              class="background rounded-lg pa-4 mb-2"
              align="center"
              justify-md="end"
            >
              <v-col cols="12" md="10">
                <div style="overflow-wrap: break-word" v-html="desc.text" />
              </v-col>
              <v-col md="2" align="end" class="d-flex">
                <atoms-button
                  @click="
                    () => {
                      editableId = desc._id
                      item = desc.text
                      $vuetify.goTo(0)
                    }
                  "
                  class="primary"
                >
                  <v-icon left>mdi-pencil</v-icon> Edit</atoms-button
                >
                <atoms-button
                  @click="
                    () => {
                      descriptions = descriptions.filter((x) => x._id !== desc._id)
                      editableId = null
                      item = ''
                    }
                  "
                  class="red white--text mx-2"
                >
                  <v-icon left>mdi-delete</v-icon>Hapus</atoms-button
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="!descriptions || (descriptions && descriptions.length < 1)">
            <atoms-text>Tidak Ada Deskripsi Yang DiTambahkan</atoms-text>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="printVersion"
              :items="[
                {
                  label: 'Versi 1 (PPN/PPH berdasarkan Platform Fee)',
                  value: 1,
                },
                {
                  label: 'Versi 2 (PPN/PPH berdasarkan Total Realisasi)',
                  value: 2,
                },
              ]"
              item-text="label"
              item-value="value"
              :menu-props="{ offsetY: true }"
              label="Print Version"
              outlined
              dense
              hide-details
              @change="isPrintVersionChanged = true"
            />
            <br />
            <atoms-text v-if="isPrintVersionChanged" style="color: red"
              >Harap submit ulang di halaman simpan invoice bila ingin menyimpan data
              <b>print version</b> terbaru!</atoms-text
            >
          </v-col>
          <v-col cols="12" v-if="!$vuetify.breakpoint.smAndDown"><v-divider /></v-col>
        </v-row>
      </v-container>
    </template>
    <v-container>
      <v-card
        class="d-none d-md-block my-4 px-2 py-4 py-md-8 black--text"
        elevation="0"
        outlined
        light
        :loading="loadingFetchInvoice"
      >
        <div v-if="loadingFetchInvoice !== true" id="printInvoice">
          <div class="main">
            <div class="header">
              <img :src="require('../../assets/hayokerja.png')" alt="logo" />
              <atoms-title><atoms-dynamic-text :value="headTitle.toUpperCase()" /></atoms-title>
            </div>
            <div class="wrapper">
              <div class="user">
                <div class="user_from">
                  <p class="user_head">FROM</p>
                  <p class="user_body">PT. HAYOOKERJA ABHINAYA INDONESIA</p>
                  <p class="user_foot">
                    Ruko Mega Grosir Cempaka Mas Blok G No.11 Kemayoran, Jakarta Pusat DKI Jakarta - Indonesia
                  </p>
                </div>
                <div class="user_to">
                  <p class="user_head">TO</p>
                  <p class="user_body">
                    <atoms-dynamic-text :value="client.name" />
                  </p>
                  <p class="user_foot">
                    <atoms-dynamic-text :value="(client.address && client.address.streetAddress1) || ''" />
                  </p>
                  <p v-if="client.contactPerson_name" class="user_pic">
                    <atoms-dynamic-text value="Nama PIC" /> :
                    <atoms-dynamic-text :value="client.contactPerson_name" />
                  </p>
                  <p v-if="client.contactPerson_phone" class="user_pic">
                    <atoms-dynamic-text value="Nomor Telp PIC" /> :
                    <atoms-dynamic-text :value="client.contactPerson_phone" />
                  </p>
                </div>
              </div>
              <div class="detail">
                <table class="tabelDetail">
                  <tr>
                    <td class="tabelDetail_title">
                      <atoms-dynamic-text :value="`No. ${headTitle}`" />
                    </td>
                    <td>:</td>
                    <td class="tabelDetail_body">{{ noInvoice }}</td>
                  </tr>
                  <tr>
                    <td class="tabelDetail_title">Date</td>
                    <td>:</td>
                    <td class="tabelDetail_body">
                      {{ $moment(data.date).format('DD MMMM YYYY') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="tabelDetail_title">Due Date</td>
                    <td>:</td>
                    <td class="tabelDetail_body">
                      {{ $moment(data.dueDate).format('DD MMMM YYYY') }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="tabel_harga">
              <table class="tabelHarga" border="0" cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    <th><atoms-dynamic-text :value="`JUDUL`" /></th>
                    <th><atoms-dynamic-text :value="`TANGGAL MULAI`" /></th>
                    <th><atoms-dynamic-text :value="`TANGGAL SELESAI`" /></th>
                    <th><atoms-dynamic-text :value="`Area`" /></th>
                    <th><atoms-dynamic-text :value="`GUDANG / LOKASI`" /></th>
                    <th style="width: 40px; padding-right: 2px; padding-left: 2px">
                      <atoms-dynamic-text :value="th_amount" />
                    </th>
                    <th style="width: 40px; padding-left: 2px; padding-right: 2px">
                      <atoms-dynamic-text :value="th_quantity" />
                    </th>
                    <th><atoms-dynamic-text :value="th_priceOffer" /></th>
                    <th v-if="data.isUseSLA">
                      <atoms-dynamic-text :value="`SLA`" />
                    </th>
                    <th><atoms-dynamic-text :value="`TOTAL`" /></th>
                  </tr>
                </thead>
                <!-- <tbody> -->
                <draggable
                  v-model="getItemsWithDefaultData"
                  @change="onDragAndDrop"
                  class="list-group"
                  ghost-class="ghost"
                  tag="tbody"
                >
                  <tr v-for="item in getItemsWithDefaultData" :key="item._id" style="cursor: grabbing">
                    <td class="deskripsi" style="padding: 7px 5px">
                      <span class="deskripsi_head">{{ item.title }}</span>
                      <span class="deskripsi_body">{{ item.description }}</span>
                    </td>
                    <td class="lokasi" style="padding: 7px 5px">
                      <span>
                        {{ item.startDate && $moment(item.startDate).format('DD MMMM YYYY') }}
                      </span>
                    </td>
                    <td class="lokasi" style="padding: 7px 5px">
                      <span>{{ item.endDate && $moment(item.endDate).format('DD MMMM YYYY') }}</span>
                    </td>
                    <td class="lokasi" style="padding: 7px 5px">
                      <span>{{ item.areaTitle }}</span>
                    </td>
                    <td class="lokasi" style="padding: 7px 5px">
                      <span>{{ item.location?.name ?? item.location }}</span>
                    </td>
                    <td class="jumlahPHL" style="padding: 7px 5px">
                      {{ item.amount }}
                    </td>
                    <td class="jumlahHariKerja" style="padding: 7px 5px">
                      {{ item.quantity }}
                    </td>
                    <td class="harga_satuan" style="padding: 7px 5px">
                      <div v-if="item.priceBase" style="display: flex">
                        <p>Rp.</p>
                        <p v-if="printVersion < 2" style="margin-left: auto">
                          {{ $idCurrency(item.priceBase, 0, 0, true, true) }}
                        </p>
                        <p v-else style="margin-left: auto">
                          {{ $addSeparator(item.priceProposed, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                    <td v-if="data.isUseSLA" style="text-align: center; padding: 7px 5px">
                      {{ $convertDecimaltoPercent(item.slaPercentage, 2) }}
                    </td>
                    <td class="total" style="padding: 7px 5px">
                      <div v-if="item.totalBase" style="display: flex">
                        <p>Rp.</p>
                        <p v-if="printVersion < 2" style="margin-left: auto">
                          <!-- {{ $idCurrency(item.totalBase, 0, 0, true, true) }} -->
                          {{ $addSeparator(item.totalBase, 0, 0, true) }}
                        </p>
                        <p v-else style="margin-left: auto">
                          {{ $addSeparator(item.totalProposed, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="printVersion < 2">
                  <td class="deskripsi font-weight-bold">
                    <span class="deskripsi_head">Biaya Jasa Platform</span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td v-if="data.isUseSLA"></td>
                  <td class="total">
                    <div style="display: flex">
                      <p>Rp.</p>
                      <p style="margin-left: auto">
                        {{ $addSeparator(data.valueGI, 0, 0, true) }}
                      </p>
                    </div>
                  </td>
                </tr>
                <!-- </tbody> -->
                <tfoot>
                  <tr>
                    <td class="subTotal">
                      <atoms-dynamic-text value="Total Realisasi" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <!-- <td style="text-align: center">{{ sumAmount }}</td>s -->
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td class="subTotalContent" style="padding-bottom: 0">
                      <div style="display: flex">
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(totalRealization) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="data.downpayment > 0">
                    <td style="padding-top: 0; padding-bottom: 0; font-style: italic; font-weight: normal">
                      <atoms-dynamic-text
                        :value="`Pembayaran Deposit (${$moment(data.dpPaymentDate).format('DD MMMM YYYY')})`"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td style="padding-top: 0; padding-bottom: 0; font-weight: normal">
                      <div style="position: relative; display: flex">
                        <!-- <p style="position: absolute; left: -23.5px">(-)</p> -->
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(data.downpayment, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="item in data.priceReduction" :key="item._id">
                    <td style="padding-top: 0; font-style: italic; font-weight: normal">
                      {{ item.title }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td style="padding-top: 0; font-weight: normal">
                      <div style="position: relative; display: flex">
                        <!-- <p style="position: absolute; left: -23.5px">(-)</p> -->
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(item.price, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="subTotal">
                      <atoms-dynamic-text value="Sub Total" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td class="subTotalContent divider">
                      <div style="display: flex">
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(subTotal, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="data.isActivePpn">
                    <td style="padding-top: 0; padding-bottom: 0; font-style: italic; font-weight: normal">
                      PPN {{ $convertDecimaltoPercent(data.ppnPercentage) }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td style="padding-top: 0; padding-bottom: 0; font-weight: normal">
                      <div style="position: relative; display: flex">
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(reCountTotalOffer.ppnValue, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="data.isActivePph">
                    <td style="padding-top: 0; font-style: italic; font-weight: normal">
                      PPH {{ $convertDecimaltoPercent(data.pphPercentage) }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td style="padding-top: 0; font-weight: normal">
                      <div style="position: relative; display: flex">
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(-1 * reCountTotalOffer.pphValue, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="total">
                      <atoms-dynamic-text :value="`Total yang harus dibayar ${total < 0 ? `(dikembalikan)` : ''}`" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-if="data.isUseSLA"></td>
                    <td class="totalContent">
                      <div style="display: flex">
                        <p>Rp.</p>
                        <p style="margin-left: auto">
                          {{ $addSeparator(total, 0, 0, true) }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="tabelHarga_footerDivider"></div>
            </div>
            <div class="catatan">
              <p class="catatan_head">Catatan :</p>
              <p class="catatan_body">Mohon untuk transfer pembayaran ke rekening berikut :</p>
              <table class="catatan_foot">
                <tr>
                  <td class="catatan_foot_head">
                    <atoms-dynamic-text value="Nama Akun" />
                  </td>
                  <td>
                    :
                    <atoms-dynamic-text value="PT. Hayookerja Abhinaya Indonesia" />
                  </td>
                </tr>
                <tr>
                  <td class="catatan_foot_head">
                    <atoms-dynamic-text value="Bank BCA (IDR)" />
                  </td>
                  <td>: <atoms-dynamic-text value="8310-15-7989" /></td>
                </tr>
              </table>
            </div>
            <div class="deskripsi">
              <ul class="deskripsi_body">
                <li v-for="item in defaultDescriptions" :key="item._id">
                  - <span style="display: inline-block" v-html="item.text"></span>
                </li>
              </ul>
            </div>
            <div class="sign">
              <div>
                <p class="sign_head">
                  <atoms-dynamic-text :value="`Jakarta, ${$moment(data.date).format('DD MMMM YYYY')}`" />
                </p>
                <p class="sign_body" style="white-space: nowrap">
                  <atoms-dynamic-text :value="namaTTD" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import draggable from 'vuedraggable'
import printJS from 'print-js'
export default {
  metaInfo() {
    return {
      title: `Cetak Invoice ${this.client?.name || ''}`,
      titleTemplate: `%s |  Nomor ${this.noInvoice} `,
    }
  },
  components: {
    draggable,
  },
  data() {
    return {
      printVersion: null,
      isPrintVersionChanged: false,
      showValidator: false,
      headTitle: 'Invoice',
      data: {},
      item: '',
      items: [],
      client: {},
      editableId: null,
      descriptions: [],
      th_amount: 'Jumlah Pekerja',
      th_quantity: 'Hari Kerja',
      th_priceOffer: 'HARGA SATUAN',
      loadingFetchInvoice: false,
      types: {
        Processing: 'Processing',
        Sprinter: 'Sprinter',
      },
    }
  },
  computed: {
    getItemsWithDefaultData: {
      get() {
        return [...(this.items || [])]
      },
      set(value) {
        this.items = value
        // ;(async function () {
        //   this.$store.commit("SET", { loading: true })
        //   const orderedItems = this.items
        //     ?.filter((it) => it._id !== "custom")
        //     ?.map((it, idx) => ({
        //       ...it,
        //       _updatedDate: new Date(new Date().setHours(0, 0 + idx, 0, 0)),
        //     }))
        //   if (orderedItems)
        //     await this.$store.dispatch("invoice/saveItems", orderedItems)
        //   this.$store.commit("SET", { loading: false })
        // }.bind(this)())
      },
    },
    noInvoice() {
      return this.data?.no
    },
    defaultDescriptions: {
      set(value) {
        const descriptions = value || [
          {
            _id: this.$uuid.v4(),
            text: `Bukti pembayaran harap di email ke: Finance@hayokerja.com`,
          },
          {
            _id: this.$uuid.v4(),
            text: `Hayokerja adalah badan usaha yang ditunjuk untuk mewakili ${
              this.client?.name || 'perusahaan tersebut'
            } melakukan pembayaran ke mitra pekerja lepas`,
          },
          {
            _id: this.$uuid.v4(),
            text: `Jumlah Pekerja adalah Jumlah Pekerja / PHL / AWB Volume`,
          },
          {
            _id: this.$uuid.v4(),
            text: `Jumlah Hari adalah Hari Kerja / Kuantitas`,
          },
          {
            _id: this.$uuid.v4(),
            text: `Platform Fee terdiri dari: Pemakaian Aplikasi, Manajement Rekrutmen, Payroll Sistem, Pemeliharaan Komunitas, Absensi dan History Database`,
          },
        ]
        this.descriptions = descriptions
      },
      get() {
        return this.descriptions
      },
    },
    sumAmount() {
      if (this.data?.type === this.types.Sprinter) {
        return this.items?.reduce((total, item) => total + item.amount, 0) || 0
      }
      return ''
    },
    totalRealization() {
      return this.data?.pureTotalOffer
    },
    subTotal() {
      const pureTotalOffer = this?.data?.pureTotalOffer || 0
      const totalPriceReduction =
        this?.data?.priceReduction?.reduce((total, item) => total + (item?.price || 0), 0) || 0
      const downpayment = this?.data?.downpayment || 0
      return pureTotalOffer - totalPriceReduction - downpayment
    },
    reCountTotalOffer() {
      const _target = JSON.parse(JSON.stringify(this.data))
      if (this.printVersion == 2) {
        // calc taxes
        const valueTarget = _target?.pureTotalOffer
        if (_target?.isActivePpn) {
          _target.ppnValue = Math.floor((_target?.ppnPercentage || 0) * valueTarget)
        } else {
          _target.ppnValue = 0
        }

        if (_target?.isActivePph) {
          _target.pphValue = Math.floor((_target?.pphPercentage || 0) * valueTarget)
        } else {
          _target.pphValue = 0
        }

        _target.balanceInvoice = (+this?.subTotal || 0) + (+_target?.ppnValue || 0) - (+_target?.pphValue || 0)
      }
      return _target
    },
    total() {
      return this.reCountTotalOffer?.balanceInvoice //this.data?.balanceInvoice
    },
    namaTTD() {
      return 'PT. Hayookerja Abhinaya Indonesia'
    },
  },
  watch: {
    'data.type': function () {
      this.adjustInvoiceType()
    },
    descriptions: {
      async handler(invDesc) {
        this.$store.commit('SET', { loading: true })
        try {
          await this.$store
            .dispatch(`invoice/update`, {
              _id: this.data?._id,
              invDesc,
            })
            .then((res) => res?.data?.result || {})
        } catch (err) {
          this.$showDialog({
            title: 'Kesalahan',
            body: err?.response?.data?.message || err?.message || 'Terdapat kesalahan ketika memperbarui deskripsi',
          })
        }
        this.$store.commit('SET', { loading: false })
      },
      deep: true,
    },
  },
  async beforeMount() {
    // fetch invoice data
    this.loadingFetchInvoice = true
    try {
      const { type, no } = this.$route.query
      if (type && no) {
        const data = (
          await this.$store.dispatch('invoice/getAll', {
            query: { _id: no },
          })
        )?.[0]
        this.data = data
        // if (this.data?.isActivePph && !this.data?.pphDitanggungHK) {
        //   this.defaultDescriptions.push({
        //     _id: this.$uuid.v4(),
        //     text: "Bukti potong pph 23 harap dikirim ke kantor hayokerja.com",
        //   });
        // }
        this.printVersion = this.data?.printVersion || 2
        this.client = this.data?.client
          ? {
              ...this.data?.client,
              streetAddress1: this.data?.client?.address?.streetAddress1 || '-',
              contactPerson_name: this.data?.client?.contactPerson?.name || '-',
              contactPerson_phone: this.data?.client?.contactPerson?.phone || '-',
            }
          : {}
        // spread items
        // this.items = this.data?.quoItems.map((item) => ({
        //   ...item,
        //   ...(this.data?.items?.find((it) => it.quoItemId === item._id) || {}),
        // }));
        this.items = this.data?.items
        this.headTitle = this.data?.title || this.headTitle
        if (this.data?.invDesc) {
          this.defaultDescriptions = this.data?.invDesc
        }
      }
    } catch (err) {
      return Promise.reject(err)
    }
    this.loadingFetchInvoice = false
    this.adjustInvoiceType()
  },
  methods: {
    print() {
      const style = require('../../constant/styles.js').default
      printJS({
        printable: 'printInvoice',
        type: 'html',
        style: style,
        scanStyles: false,
      })
    },
    async onDragAndDrop(res) {
      const moved = res?.moved || {}
      const _id = moved?.element?._id
      const quotationId = this?.data?._id
      const newIndex = moved?.newIndex
      const oldIndex = moved?.oldIndex
      await this.$api.patch('InvoiceItems/adjustOrder', {
        _id,
        quotationId,
        newIndex,
        oldIndex,
      })
    },
    adjustInvoiceType() {
      switch (this.data?.type) {
        case 'Processing':
          this.th_amount = 'JUMLAH PHL'
          this.th_quantity = 'HARI KERJA'
          this.th_priceOffer = 'HARGA SATUAN'
          break
        case 'Sprinter':
          this.th_amount = 'AWB VOL'
          this.th_quantity = 'QTY'
          this.th_priceOffer = 'Harga @AWB'
          break
      }
    },
  },
}
</script>
<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
@page {
  margin: 0mm;
  size: potrait;
}
.main * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'calibri';
}
.main {
  margin: 0 auto;
  min-width: calc(210mm + 40px);
  min-height: calc(297mm + 30px);
  padding: 30px 40px;
  display: block;
}
.main p {
  margin-bottom: 0;
}
.main th {
  font-size: 80%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: solid grey 2px;
  margin-bottom: 20px;
}
.header img {
  width: 250px;
}
.header h1 {
  font-size: 40px;
  letter-spacing: 0.3px;
  margin-left: auto;
}
.user_from {
  margin-bottom: 20px;
}
.user_to {
  margin-bottom: 20px;
}
.user_head {
  font-weight: bold;
  color: grey;
}
.user_body {
  font-weight: bold;
  color: black;
}
.user_foot {
  word-wrap: break-word;
  width: 400px;
}
.user_pic {
  font-weight: bold;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.detail {
  margin-left: auto;
}
.detail .tabelDetail_title {
  font-weight: bold;
  padding: 0 10px;
  text-align: right;
}
.detail .tabelDetail_body {
  padding: 0 10px;
}
.tabelHarga {
  width: 100%;
}
.tabelHarga td {
  padding: 5px 5px;
}
.tabelHarga thead {
  text-align: center;
  background-color: #1e82e6;
  -webkit-print-color-adjust: exact;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}
.tabelHarga tbody td {
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: top;
}
.tabelHarga tbody tr:first-of-type td {
  padding-top: 5px;
}
.tabelHarga tbody tr:last-of-type td {
  padding-bottom: 5px;
}
.tabelHarga tbody .deskripsi_head {
  font-weight: bold;
}
.tabelHarga tbody .deskripsi_body {
  display: block;
  font-style: italic;
  padding-left: 15px;
}
.tabelHarga tbody .lokasi {
  text-align: center;
  align-items: center;
  max-width: 200px;
}
.tabelHarga tbody .jumlahPHL {
  text-align: center;
}
.tabelHarga tbody .jumlahHariKerja {
  text-align: center;
}
.tabelHarga tbody .harga_satuan {
  text-align: right;
}
.tabelHarga tbody .SLA {
  text-align: center;
}
.tabelHarga tbody .total {
  text-align: right;
}
.tabelHarga tfoot {
  font-weight: bold;
}
.tabelHarga tfoot tr:first-of-type td {
  border-top: 1px solid grey;
}
.tabelHarga tfoot .subTotal {
  padding-bottom: 0px;
  font-weight: bold;
}
.tabelHarga tfoot .subTotalContent {
  font-weight: bold;
  text-align: right;
}
.tabelHarga tfoot .subTotalContent.divider {
  padding-bottom: 0;
  border-bottom: 1.5px solid black;
}
.tabelHarga tfoot .pph {
  padding-top: 0px;
  padding-bottom: 0px;
  font-style: italic;
  font-weight: normal;
}
.tabelHarga tfoot .pphContent {
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: normal;
}
.tabelHarga tfoot .penguranganTagihan {
  padding-top: 0px;
  padding-bottom: 0px;
  font-style: italic;
  font-weight: normal;
}
.tabelHarga tfoot .penguranganTagihanContent {
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: normal;
}
.tabelHarga tfoot .total {
  padding-top: 15px;
}
.tabelHarga tfoot .totalContent {
  padding-top: 15px;
  font-weight: bold;
  text-align: right;
  border-bottom: 2px solid black;
}
.tabelHarga_footerDivider {
  margin-top: 5px;
  background-color: #1e82e6;
  -webkit-print-color-adjust: exact;
  height: 20px;
}
.catatan {
  margin-top: 20px;
  font-style: italic;
  margin-bottom: 20px;
}
.catatan_head {
  font-weight: bold;
}
.catatan_foot_head {
  padding-right: 200px;
}
.deskripsi {
  margin-bottom: 20px;
}
.deskripsi_head {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  font-style: italic;
}
.deskripsi_head_important {
  font-weight: bold;
}
.deskripsi_body {
  list-style: none;
}
.deskripsi_body li {
  display: flex;
  align-items: flex-start;
}
.sign {
  width: 218px;
  min-height: 62px;
  text-align: center;
  margin-left: auto;
}
.sign_head {
  padding-bottom: 125px;
}
.sign_body {
  font-weight: bold;
}
</style>
